import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import axios from "axios";
import { useRef, useState } from "react";
import Swal from "sweetalert2";
import "./App.css";
import Logo from "./images/upload.png";

const Input = styled("input")({
  display: "none"
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#dcdcdc",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary
}));

function App() {
  const [query, setQuery] = useState("idle");
  const [subject, setSubject] = useState(null);
  const [description, setDescription] = useState(null);
  const [category, setCategory] = useState("");
  const [selectedFiles, setSelectedFiles]= useState([]);
  const [checked, setChecked] = useState(false);
  const inputRef = useRef();

  const clickSubmit = () => {
    // if (subject === "" || description === "") {
    //   Swal.fire({
    //     title: "Error!",
    //     text: "subject 또는 description 이 비어있습니다.",
    //     icon: "error",
    //     confirmButtonText: "OK"
    //   });
    //   return;
    // }

    setQuery("progress");
    handleSubmit();
  };

  const handleFileInput = (e) => {
    console.log("file is " + e.target.files[0].name);
    console.log("file is " + e.target.files);
    console.log("files length is " + e.target.files?.length);

    setSelectedFiles([...e.target.files]);
  };

  const handleDelete = (indexToRemove) => {
    console.info("You clicked the delete icon. length : " + selectedFiles.length + " indexToRemove : " + indexToRemove);

    if (indexToRemove !== null && indexToRemove !== undefined && selectedFiles.length > 1) {
      console.info("clicked index");
      const newArray = selectedFiles.filter((_, index) => index !== indexToRemove);
      setSelectedFiles(newArray);
    } else {
      console.info("clicked single file");
      setSelectedFiles([]);
    }

    inputRef.current.value = "";
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    // let text = e.target.value;
    // text = text.replace("\r\n", "<br>");
    setDescription(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const clearAllData = () => {
    setSubject(null);
    setDescription(null);
    setCategory("");
    setSelectedFiles([]);
    inputRef.current.value = "";
  };

  const handleSubmit = () => {
    const formData = new FormData();

    const isSingleFile = selectedFiles.length <= 1;

    if (isSingleFile) {
      //파일 한개
      formData.append("file", selectedFiles[0]);
    } else {
      //파일 여러개
      // formData.append("files", selectedFiles);
      for (let i = 0 ; i < selectedFiles.length ; i++) {
        formData.append("files", selectedFiles[i]);
    }
    }

    formData.append("subject", subject || "empty");
    formData.append("description", description || "empty");

    if (category !== "") {
      formData.append("category", category);
    }

    const url = isSingleFile ? "http://bonkab.com:8989/" : "http://bonkab.com:8989/multipart";

    return axios
      .post(url, formData)
      .then((res) => {
        let param = {
          title: "성공!",
          text: "파일 업로드를 성공했어요.",
          icon: "success",
          confirmButtonText: "OK"
        };

        if (checked) {
          param.showDenyButton = true;
          param.denyButtonText = "Copy";
        }

        Swal.fire(param).then((result) => {
          if (result.isDenied) {
            doCopy("http://bonkab.com:8989/download/" + res?.data?.fileInfo?.filename);
          }
        });
        setQuery("success");
        clearAllData();
      })
      .catch((err) => {
        Swal.fire({
          title: "실패!",
          text: "파일 업로드를 실패했어요.",
          icon: "error",
          confirmButtonText: "OK"
        });
        setQuery("fail");
      });
  };

  const handleChange = (e) => {
    setChecked(e.target.checked);
  };

  const handleCopyClipBoard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (e) {
      console.log('복사에 실패하였습니다 ', e);
    }
  };

  //https://kyounghwan01.github.io/blog/React/clipboard-copy/#%E1%84%8B%E1%85%A8%E1%84%89%E1%85%B5-%E1%84%8F%E1%85%A9%E1%84%83%E1%85%B3
  const doCopy = text => {
    // 흐름 1.
    if (navigator.clipboard) {
      // (IE는 사용 못하고, 크롬은 66버전 이상일때 사용 가능합니다.)
      console.log("doCopy 111");
      navigator.clipboard
        .writeText(text)
        .then(() => {
          Swal.fire({
            // position: 'top-end',
            icon: 'success',
            title: '클립보드에 복사되었습니다.',
            showConfirmButton: false,
            timer: 1000
          });
        })
        .catch(() => {
          alert("복사를 다시 시도해주세요.");
        });
    } else {
      // 흐름 2.
      console.log("doCopy 222");
      if (!document.queryCommandSupported("copy")) {
        return alert("복사하기가 지원되지 않는 브라우저입니다.");
      }

      // 흐름 3.
      const textarea = document.createElement("textarea");
      textarea.value = text;
      textarea.style.top = 0;
      textarea.style.left = 0;
      textarea.style.position = "fixed";

      // 흐름 4.
      document.body.appendChild(textarea);
      // focus() -> 사파리 브라우저 서포팅
      textarea.focus();
      // select() -> 사용자가 입력한 내용을 영역을 설정할 때 필요
      textarea.select();
      // 흐름 5.
      document.execCommand("copy");
      // 흐름 6.
      document.body.removeChild(textarea);
      Swal.fire({
        // position: 'top-end',
        icon: 'success',
        title: '클립보드에 복사되었습니다.',
        showConfirmButton: false,
        timer: 1000
      });
    }
  };

  return (
    <div className="App">
      <Box sx={{ width: "90%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}>
            <img src={Logo} />
          </Grid>
          <Grid item xs={12}>
            <Item elevation={0}>
              <TextField id="outlined-basic" label="Subject" variant="outlined" onChange={handleSubjectChange} sx={{ m: 1, width: "90%" }} value={subject} />
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Item elevation={0}>
              <TextField
                id="filled-textarea"
                label="Description"
                placeholder="설명을 입력하세요."
                multiline
                value={description}
                variant="outlined"
                sx={{ m: 1, width: "90%" }}
                onChange={handleDescriptionChange}
              />
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Item elevation={0}>
              <TextField id="outlined-basic" label="Category" variant="outlined" onChange={handleCategoryChange} sx={{ m: 1, width: "90%" }} value={category} />
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Item elevation={0}>
            <FormControlLabel control={
              <Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />} label="Link" />
            
            </Item>
          </Grid>

          <Grid item xs={12}>
            <Item elevation={0}>
              <div style={{ minHeight: 40 }}>
                {query === "progress" ? (
                  <Fade
                    in={query === "progress"}
                    style={{
                      transitionDelay: query === "progress" ? "0ms" : "0ms"
                    }}
                    unmountOnExit
                  >
                    <CircularProgress />
                  </Fade>
                ) : selectedFiles.length === 0 ? (
                  <div>업로드 된 파일이 없습니다</div>
                ) : selectedFiles.length === 1 ? <Chip label={selectedFiles[0].name} variant="outlined" onDelete={handleDelete} /> : (

                  // <Chip label={selectedFile.name} variant="outlined" onDelete={handleDelete} />
                  selectedFiles.map((file, index) => (
                    <Chip
                        key={index}
                        label={file.name}
                        variant="outlined"
                        onDelete={() => handleDelete(index)}
                    />
                ))
                )}
              </div>
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Item elevation={0}>
              <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
                <label htmlFor="contained-button-file">
                  <Input id="contained-button-file" multiple type="file" ref={inputRef} onChange={(e) => handleFileInput(e)} />
                  <Button variant="contained" component="span">
                    Upload
                  </Button>
                </label>

                <Button variant="contained" component="span" onClick={clickSubmit}>
                  Submit
                </Button>
              </Stack>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default App;
